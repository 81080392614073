import { REPORT_IN_EXECUTIVE_SUMMARY, THRESHOLD_DESCRIPTION } from 'pages/ExecutiveSummary/constants';

export const getRiskRatingOverviewBody = (id) => {
  return {
    intent: '',
    fields: [
      'EnvironmentId',
      'EnvironmentName',
      'RiskRating',
      'RiskRatingScore',
      'RatingSummary',
      'ImpactLevelPills',
      'RiskRatingRecommendedAction',
      'Impact',
      'ImpactLevel',
      'Likelihood',
      'LikelihoodLevel',
      'LikelihoodPillsDataStorage',
      'LikelihoodPillsDataStorage_Value',
      'LikelihoodPillsDataAccess',
      'LikelihoodPillsDataAccess_Value',
      'ProgressNumber',
      'ProgressDate',
      'Threshold',
    ],
    Criterion: [
      {
        Field: 'AssessmentId',
        Operator: '=',
        Value: id,
      },
      {
        Field: 'ReportInExecutiveSummary',
        Operator: '=',
        Value: REPORT_IN_EXECUTIVE_SUMMARY,
      },
      {
        Field: 'ThresholdDescription',
        Operator: '<>',
        Value: THRESHOLD_DESCRIPTION,
      },
    ],
    sort: [
      {
        field: 'ProgressNumber',
        order: 'DESC',
      },
      {
        field: 'ProgressDate',
        order: 'DESC',
      },
      {
        field: 'Threshold',
        order: 'DESC',
      },
    ],
    page: 0,
    pageSize: 0,
  };
};

export const getRiskRatingOverviewBodyForPortal = (id) => {
  return {
    intent: '',
    fields: [
      'EnvironmentId',
      'EnvironmentName',
      'RiskRating',
      'RiskRatingScore',
      'RatingSummary',
      'ImpactLevelPills',
      'RiskRatingRecommendedAction',
      'Impact',
      'ImpactLevel',
      'Likelihood',
      'LikelihoodLevel',
      'LikelihoodPillsDataStorage',
      'LikelihoodPillsDataStorage_Value',
      'LikelihoodPillsDataAccess',
      'LikelihoodPillsDataAccess_Value',
      'ProgressNumber',
      'ProgressDate',
      'Threshold',
      'RiskRatingSummary',
      'RiskRecommendation',
      'PIIDataAccessId',
      'PHIDataAccessId',
      'PCIDataAccessId',
      'EmployeeProprietaryDataAccessId',
      'OffshoreDataAccessId',
      'OffshoreDataStorageId',
    ],
    Criterion: [
      {
        Field: 'AssessmentId',
        Operator: '=',
        Value: id,
      }
    ],
    sort: [],
    page: 0,
    pageSize: 0,
  };
};

export const getAssessmentOverviewBody = (id) => {
  return {
    intent: '',
    fields: [
      'ClientName',
      'VendorName',
      'VendorDescription',
      'ProductName',
      'ProductDescription',
      'ProductUrl',
      'Environment',
      'SummaryDate',
      'ValidationTypeId',
      'ValidationTypeName',
      'LastUpdated',
      'PreparedDate'
    ],
    Criterion: [
      {
        Field: 'AssessmentId',
        Operator: '=',
        Value: id,
      },
    ],
    sort: [],
    page: 0,
    pageSize: 0,
  };
};

export const getAssessmentOverviewBodyForPortal = (id) => {
  return {
    intent: '',
    fields: [
      'VendorId',
      'VendorName',
      'VendorDescription',
      'ProductName',
      'ProductDescription',
      'ProductUrl',
      'Environment',
      'SummaryDate',
      'ValidationTypeId',
      'ValidationTypeName',
      'LastUpdated',
      'Source',
      'DisplayExecutiveSummaryLink',
      'AssessmentType',
      'IsEscalated',
      'AssessmentStartDate',
      'AssessmentCompletedDate',
      'IsEscalated',
      'IsExpedite',
      'IsPaused',
      'EditAssessmentOptionList',
      'LatestAssessmentActionLog',
      'AnswerBySecCert',
      'AssessmentCorrelationId',
      'VendorAlias',
      'IsValidatedAssessment'
    ],
    Criterion: [
      {
        Field: 'AssessmentId',
        Operator: '=',
        Value: id,
      },
    ],
    sort: [],
    page: 0,
    pageSize: 0,
  };
};

export const getEvidenceReviewBody = (id) => {
  return {
    intent: '',
    fields: [
      'EnvironmentId',
      'EnvironmentName',
      'EvidenceReviewId',
      'ResultOfTesting',
      'EvidenceReviewed',
      'Requirement',
      'QuestionText',
      'DescriptionOfEvidence',
      'Outcome',
      'Outcome_Value',
      'ControlOrder',
      'ControlNumber',
      'ControlName',
      'QuestionnaireTemplateItemId',
      'TierName'
    ],
    Criterion: [
      {
        Field: 'AssessmentId',
        Operator: '=',
        Value: id,
      },
    ],
    sort: [
      {
        field: 'ControlOrder',
        order: 'ASC',
      },
    ],
    page: 0,
    pageSize: 0,
  };
};

export const getEvidenceReviewBodyForPortal = (id) => {
  return {
    intent: '',
    fields: [
      "RecommendationStatus",
      "NotesToVendor",
      "QuestionnaireTemplateRequirementId",
      "AssessmentQuestionnaireId",
      "RequirementId",
      "RequirementStatus",
      "RequirementNumber",
      "RequirementName",
      "RequirementType",
      "RequirementGroup",
      "ControlNumber",
      "DefaultTier",
      "AssessmentId",
      "QuestionnaireTemplateId",
      "AssessmentQuestionnaireName",
      "AssessmentName",
      "ProductVendorId",
      "RecommendationStatus",
      "Severity",
      "InadequecyReasonIPIList",
      "QuestionnaireTemplateRequirementId",
      "AcceptedByVendor",
      "AssessmentQuestionnaireId",
      "Adequacy",
      "TierName",
      "AssessmentQuestionnaireQuestionResponseList",
      "QuestionText",
      "ResultsOfTesting"
    ],
    Criterion: [
      {
        Field: 'AssessmentId',
        Operator: '=',
        Value: id,
      },
    ],
    sort: [],
    page: 0,
    pageSize: 0,
  };
};

export const getCyberRiskScoreBody = (id) => {
  return {
    intent: '',
    fields: [
      'CyberRiskScoreSource',
      'RiskScoreElement',
      'RiskScoreLetterGrade',
      'RiskReconPreAssessmentDataLoadDate',
      'BitSightPreAssessmentDataLoadDate',
      'SecurityScorecardDateLoaded',
      'RiskReconRating',
      'BitsightScore',
      'SecurityScoreCardOverallScore',
      'WebEncryption',
      'ApplicationSecurity',
      'SystemReputation',
      'SoftwarePatching',
      'EmailSecurity',
      'DSNSecurity',
      'BreachEvent',
      'CorlRiskReconRiskRating',
      'NetworkFiltering',
      'SystemHosting',
      'BitsightIPAddress',
      'BitsightUnsolicitedCommunication',
      'BitsightUnsolicitedCommunication_Value',
      'BitsightPotentiallyExploited',
      'BitsightPotentiallyExploited_Value',
      'BitsightBotnetInfections',
      'BitsightBotnetInfections_Value',
      'BitsigntMalwareServers',
      'BitsigntMalwareServers_Value',
      'BitsightSpamPropogation',
      'BitsightSpamPropogation_Value',
      'SPFDomains',
      'SPFDomains_Value',
      'DKIMRecords',
      'DKIMRecords_Value',
      'TLSSSLConfigurations',
      'TLSSSLConfigurations_Value',
      'DNSSECRecords',
      'DNSSECRecords_Value',
      'WebApplicationHeaders',
      'WebApplicationHeaders_Value',
      'InsecureSystems',
      'InsecureSystems_Value',
      'ButsightOpenPorts',
      'ButsightOpenPorts_Value',
      'BitsightFileSharing',
      'BitsightFileSharing_Value',
      'TLLSSLCertificates',
      'TLLSSLCertificates_Value',
      'PatchingCadence',
      'PatchingCadence_Value',
      'ServerSoftware',
      'ServerSoftware_Value',
      'DesktopSoftware',
      'DesktopSoftware_Value',
      'MobileSoftware',
      'MobileSoftware_Value',
      'MobileApplicationSecurity',
      'MobileApplicationSecurity_Value',
      'BitsightBreaches',
      'BitsightBreaches_Value',
      'CorlBitsightRiskRating',
      'CorlBitsightRistRating_Value',
      'SSSocialEngineering',
      'SSPatchingCadence',
      'SSNetworkSecurity',
      'SSIPReputation',
      'SSNetworkSecurity',
      'SSInformationLeak',
      'SSHackerChatter',
      'SSEndpointSecurity',
      'SSDNSHealth',
      'SSCubitScore',
      'SSApplicationSecurity',
      'SecurityScoreCardVendorIndustry',
    ],
    Criterion: [
      {
        Field: 'AssessmentId',
        Operator: '=',
        Value: id,
      },
    ],
    sort: [],
    page: 0,
    pageSize: 0,
  };
};

export const getRiskRatingControlSummaryBody = (id) => {
  return {
    intent: '',
    fields: ['EnvironmentId', 'EnvironmentName', 'Tier1Data', 'Tier2Data', 'Tier3Data', 'Tier4Data'],
    Criterion: [
      {
        Field: 'AssessmentId',
        Operator: '=',
        Value: id,
      },
    ],
    sort: [],
    page: 0,
    pageSize: 0,
  };
};

export const getRiskRatingControlSummaryBodyForPortal = (id) => {
  return {
    intent: '',
    fields: [
      "AssessmentId",
      'Tier1Data',
      'Tier2Data',
      'Tier3Data',
      'Tier4Data'
    ],
    Criterion: [
      {
        Field: 'AssessmentId',
        Operator: '=',
        Value: id,
      },
    ],
    sort: [],
    page: 0,
    pageSize: 0,
  };
};

export const getRiskFindingSummaryBody = (id) => {
  return {
    intent: '',
    fields: [
      'EnvironmentId',
      'EnvironmentName',
      'RiskSummaryId',
      'Priority',
      'PriorityName',
      'RiskDescription',
      'ControlNumber',
      'ControlName',
      'StatusId',
      'Status',
      'CompletionDate',
      'RemediationStrategyAcceptByVendor',
      'AcceptedByVendor',
      'ContractuallyAgreedUponDate',
      'RecommendedDueDate',
      'ProposedRecommendation',
      'AssessmentResults',
      'TierName'
    ],
    Criterion: [
      {
        Field: 'AssessmentId',
        Operator: '=',
        Value: id,
      },
    ],
    sort: [],
    page: 0,
    pageSize: 0,
  };
};

export const getRiskFindingSummaryBodyForPortal = (id) => {
  return {
    intent: '',
    fields: [
      "RecommendationStatus",
      "NotesToVendor",
      "QuestionnaireTemplateRequirementId",
      "AssessmentQuestionnaireId",
      "RequirementId",
      "RequirementStatus",
      "RequirementNumber",
      "RequirementName",
      "RequirementType",
      "RequirementGroup",
      "ControlNumber",
      "DefaultTier",
      "AssessmentId",
      "QuestionnaireTemplateId",
      "AssessmentQuestionnaireName",
      "AssessmentName",
      "ProductVendorId",
      "RecommendationStatus",
      "Severity",
      "SeverityId",
      "InadequecyReasonIPIList",
      "QuestionnaireTemplateRequirementId",
      "AcceptedByVendor",
      "TierName",
      "RemediationDueDate",
      "CountOfResolvedRiskFindings"
    ],
    Criterion: [
      {
        Field: 'AssessmentId',
        Operator: '=',
        Value: id,
      },
    ],
    sort: [],
    page: 0,
    pageSize: 0,
  };
};

export const getVendorCollaborationScoreBody = (id) => {
  return {
    intent: '',
    fields: ['OverallCollaborationScore', 'ResponseTimeliness', 'QuestionnaireParticipation', 'RemediationTimeliness', 'TPIRProfileCompleteness', 'AssessmentId'],
    criterion: [
      {
        Field: 'AssessmentId',
        Operator: '=',
        Value: id,
      },
    ],
    sort: [],
    page: 0,
    pageSize: 0,
  };
};
